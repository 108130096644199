// 查询 - 社区 - 字典表
const getCommunityOpsUrl = `/gateway/hc-portal/szdz/tenantList`;

// 查询 - 小区/幢/单元 - 字典表
const getXqLzDyOpsUrl = `/gateway/hc-space/space/list`;

// 查询 - 空间中心 - 列表
const getSpaceCenterListUrl = `/gateway/hc-space/space/query`;

// 查询 - 空间中心 - 详情
const getSpaceCenterDetailUrl = `/gateway/hc-space/space/detail`;

// 导出 - 空间中心 - excel表格
const exportSpaceCenterExcelUrl = `/gateway/hc-space/space/exportSpaceList`;

// 新增 - 空间中心
const addSpaceCenterInfoUrl = `/gateway/hc-space/space/addHouseSpaceInfo`;

// 编辑 - 空间中心
const editSpaceCenterInfoUrl = `/gateway/hc-space/space/editHouseSpaceInfo`;

// 删除 - 空间中心
const deleteSpaceCenterInfoUrl = `/gateway/hc-space/space/deleteHouseSpaceInfo`;

export {
  getCommunityOpsUrl,
  getXqLzDyOpsUrl,
  getSpaceCenterListUrl,
  getSpaceCenterDetailUrl,
  exportSpaceCenterExcelUrl,
  addSpaceCenterInfoUrl,
  editSpaceCenterInfoUrl,
  deleteSpaceCenterInfoUrl
};
