import { mapHelper } from '@/utils/common.js';

// 操作类型
const handType = [
  { value: 'add', label: '新增' },
  { value: 'edit', label: '编辑' },
  { value: 'view', label: '查看' },
  { value: 'detail', label: '详情' }
];
const { map: handTypeMap, setOps: setHandTypeOps } = mapHelper.setMap(handType);

export { handType, handTypeMap, setHandTypeOps };
