<template>
  <div class="realNameAuditForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      queryMethod="post"
      :queryMethodTe="true"
      @update="update"
    >
      <el-form-item label="小区名称" prop="communityId" :rules="[{ required: true, message: '请选择小区名称', trigger: 'change' }]">
        <v-select v-model="form.communityId" clearable placeholder="请选择小区名称" :options="xqOptions" @change="xqOnChange" />
      </el-form-item>
      <el-form-item label="楼幢号" prop="buildingId" :rules="[{ required: true, message: '请选择楼幢号', trigger: 'change' }]">
        <v-select v-model="form.buildingId" clearable placeholder="请选择楼幢号" :options="lzOptions" @change="lzOnChange" />
      </el-form-item>
      <el-form-item label="单元号" prop="unitId" :rules="[{ required: true, message: '请选择单元号', trigger: 'change' }]">
        <v-select v-model="form.unitId" clearable placeholder="请选择单元号" :options="dyOptions" />
      </el-form-item>
      <el-form-item label="房号" prop="houseName" :rules="[{ required: true, message: '请输入房号', trigger: 'blur' }]">
        <v-input v-model="form.houseName" clearable placeholder="请输入房号" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { getXqLzDyOpsUrl, getSpaceCenterDetailUrl, addSpaceCenterInfoUrl, editSpaceCenterInfoUrl } from './api.js';
import { handTypeMap } from './map.js';

export default {
  name: 'pubCapSpacCenSpaceCenterForm',
  components: {},
  data() {
    return {
      xqOptions: [],
      lzOptions: [],
      dyOptions: [],
      submitConfig: {
        queryUrl: getSpaceCenterDetailUrl,
        submitUrl: ''
      },
      form: {
        spaceId: void 0,
        communityId: '',
        communityName: '',
        buildingId: '',
        building: '',
        unitId: '',
        unit: '',
        houseName: ''
      },
      xqChangeNum: 0,
      lzChangeNum: 0
    };
  },
  watch: {},
  created() {
    const { hand } = this.$route.query;
    if (hand === 'add') {
      this.xqChangeNum = 1;
      this.lzChangeNum = 1;
    }
    this.getXqOpsList();
  },
  mounted() {
    const { hand, spaceId } = this.$route.query;
    this.submitConfig.submitUrl = hand === 'add' ? addSpaceCenterInfoUrl : editSpaceCenterInfoUrl;
    if (spaceId) {
      this.$refs.formPanel.getData({ spaceId });
    }
    this.$setBreadList(handTypeMap[hand]);
  },
  methods: {
    getXqOpsList() {
      this.$axios
        .get(getXqLzDyOpsUrl, { params: { spaceId: this.$route.query.tenantId, type: '10' } })
        .then(res => {
          if (res && res.code === 200) {
            this.xqOptions = res.data.map(item => ({ label: item.name, value: item.spaceId }));
          }
        })
        .catch(() => {});
    },
    getLzOpsList() {
      this.$axios
        .get(getXqLzDyOpsUrl, { params: { spaceId: this.form.communityId, type: '13' } })
        .then(res => {
          if (res && res.code === 200) {
            this.lzOptions = res.data.map(item => ({ label: item.name, value: item.spaceId }));
          }
        })
        .catch(() => {});
    },
    getDyOpsList() {
      this.$axios
        .get(getXqLzDyOpsUrl, { params: { spaceId: this.form.buildingId, type: '16' } })
        .then(res => {
          if (res && res.code === 200) {
            this.dyOptions = res.data.map(item => ({ label: item.name, value: item.spaceId }));
          }
        })
        .catch(() => {});
    },
    update(data) {
      Object.assign(this.form, {
        spaceId: void 0,
        communityId: data.communityId,
        communityName: data.communityName,
        buildingId: data.buildingId,
        building: data.buildingName,
        unitId: data.unitId,
        unit: data.unitName,
        houseName: data.roomName
      });
      if (data.communityId === '') {
      }
      this.getLzOpsList();
      this.getDyOpsList();
    },
    xqOnChange(value) {
      this.xqChangeNum++;
      if (this.xqChangeNum <= 1) return;
      this.form.buildingId = '';
      this.form.building = '';
      if (this.form.communityId) {
        this.getLzOpsList();
      } else {
        this.lzOptions = [];
      }
    },
    lzOnChange(value) {
      this.lzChangeNum++;
      if (this.lzChangeNum <= 1) return;
      this.form.unitId = '';
      this.form.unit = '';
      if (this.form.buildingId) {
        this.getDyOpsList();
      } else {
        this.dyOptions = [];
      }
    },
    async submitBefore() {
      const valid = await this.$refs.formPanel.validateAll();
      if (valid) {
        this.form.communityName = (this.xqOptions.find(item => item.value === this.form.communityId) || {}).label;
        this.form.building = (this.lzOptions.find(item => item.value === this.form.buildingId) || {}).label;
        this.form.unit = (this.dyOptions.find(item => item.value === this.form.unitId) || {}).label;
        this.form.spaceId = this.$route.query.spaceId || void 0;
      }
      return valid ? this.form : valid;
    }
  }
};
</script>
<style lang="less" scoped>
.realNameAuditForm {
  box-sizing: border-box;
  height: 100%;
  .formItem {
    ::v-deep .el-form-item__content {
      display: block;
      .vTag {
        margin-right: 20px;
      }
    }
  }
  .address {
    .item {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      .item-l {
        width: 200px;
        font-size: 14px;
        line-height: 16px;
        overflow-x: auto;
      }
      .item-r {
        margin-left: 20px;
        .v-control {
          margin-right: 10px;
        }
      }
    }
  }
  .caritem {
    margin-bottom: 10px;
  }
}
</style>
<style lang="less">
.realNameAuditForm-addAddress .v-control {
  display: flex;
  align-items: center;
}
.realNameAuditForm-addAddress .v-control label {
  width: 80px !important;
}

/* 用户标签 v-cascader */
.userManageForm_cascader-v-1828066a .node-flex {
  display: flex;
  align-items: center;
  .node-flex_label {
    display: inline-block;
  }
  .node-flex_input {
    display: none;
    flex: 1;
    text-align: right;
    margin-left: 8px;
  }
  .node-flex_remark {
    margin-left: 8px;
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &:hover {
    .node-flex_input {
      display: block;
    }
    .node-flex_remark {
      display: none;
    }
  }
}
/** end */
</style>
